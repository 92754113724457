<template>
  <div class="hello">
    <h1>소비잇</h1>
    <div class="corner">
      <h2>루팅/탈옥 여부</h2>
      <div class="content">
        루팅/탈옥 여부 : <code>{{ is_rooting }}</code><p/>
      </div>
    </div>
    <div class="corner">
      <h2>window.open 대체</h2>
      <div class="content">
        네이버 열기 : <v-btn v-on:click="onButtonOpenNaver">m.naver.com 열기</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>confirm 시험</h2>
      <div class="content">
        확인하기 : <v-btn v-on:click="onButtonConfirm">confirm</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>앱 추적 허용(iOS)</h2>
      <div class="content">
        앱 추적 허용 상태 확인 : <v-btn v-on:click="onButtonGetTrackingAuthorizationStatus">확인</v-btn><p/>
        앱 추적 허용 상태 : <code>{{ trackingAuthorizationStatus }}</code><p/>
        앱 추적 허용 요청 : <v-btn v-on:click="onButtonRequestTrackingAuthorization">요청</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>URL Scheme 시험</h2>
      <div class="content">
        <td><v-text-field row-height="15" rows="1" outlined v-model="url_scheme_link" placeholder="Enter offerwall_uid..."/></td>
        URL Scheme 이동(앱 미설치시 스토어 이동) : <v-btn v-on:click="onButtonUrlSchemeHref">location.href 사용</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>앱 현재 버전</h2>
      <div class="content">
        앱 버전 가져오기(AOS/IOS) : <v-btn v-on:click="onButtonNativeGetAppVersion">가져오기</v-btn><p/>
        앱 버전(AOS/IOS) : <code>{{ app_version_code }} ({{ app_version_name }})</code><p/>
        스토어 버전 가져오기(IOS) : <v-btn v-on:click="onButtonNativeGetAppVersionLastest">가져오기</v-btn><p/>
        스토어 버전(IOS) : <code>{{ store_version_name }}</code><p/>
      </div>
      <h2>앱 업데이트</h2>
      <div class="content">
        업데이트 가능 여부(AOS/IOS) : <code>{{ update_available }}</code><p/>
        권장 업데이트 가능 여부(AOS) : <code>{{ update_available_flexible }}</code><p/>
        즉시 업데이트 가능 여부(AOS) : <code>{{ update_available_immediate }}</code><p/>
        업데이트 우선순위(AOS) : <code>{{ update_available_priority }}</code><p/>
        스토어 업데이트 제공 이후의 일 수(AOS) : <code>{{ update_available_days }}</code><p/>
        권장 업데이트 시작(AOS) : <v-btn v-on:click="onButtonNativeUpdateFlexible">업데이트 시작</v-btn><p/>
        즉시 업데이트 시작(AOS) : <v-btn v-on:click="onButtonNativeUpdateImmediate">업데이트 시작</v-btn><p/>
        업데이트 다운로드 됨(AOS) : <code>{{ update_downloaded }}</code><p/>
        업데이트 다운로드 됨 메시지(AOS) : <code>{{ update_downloaded_message }}</code><p/>
        업데이트 취소됨(AOS) : <code>{{ update_canceled }}</code><p/>
        업데이트 취소됨 메시지(AOS) : <code>{{ update_canceled_message }}</code><p/>
        업데이트 완료 시작(AOS) : <v-btn v-on:click="onButtonNativeUpdateComplete">시작</v-btn><p/>
        스토어 열기(AOS/IOS) : <v-btn v-on:click="onButtonNativeOpenStore">열기</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>앱 생명 주기</h2>
      <div class="content">
        onResume 횟수 : <code>{{ received_on_resume_count }}</code><p/>
        onResume 시간 : <code>{{ received_on_resume_time }}</code><p/>
        onPause 횟수 : <code>{{ received_on_pause_count }}</code><p/>
        onPause 시간 : <code>{{ received_on_pause_time }}</code><p/>
        앱 종료 : <v-btn v-on:click="onButtonNativeFinish">Finish 호출하기</v-btn>
      </div>
    </div>
    <div class="corner">
      <h2>앱 알림 권한</h2>
      <div class="content">
        알림 권한 허용 확인 : <v-btn v-on:click="onButtonNativeCheckPermissionNotification">확인하기</v-btn><p/>
        알림 권한 허용 : <code>{{ notification_permission_enabled }}</code><p/>
        알림 권한 요청 : <v-btn v-on:click="onButtonNativeRequestPermissionNotification">요청하기</v-btn><p/>
        권한 타입 확인 : <v-btn v-on:click="onButtonNativeRequestPermissionNotificationType">확인하기</v-btn><p/>
        권한 타입 : <code>{{ notification_permission_request_type }}</code>
      </div>
    </div>
    <div class="corner">
      <h2>텍스트(URL) 공유</h2>
      <div class="content">
        <table><tr>
          <td>텍스트 공유 :</td>
          <td><v-text-field row-height="15" rows="1" outlined v-model="share_text" placeholder="Enter share_text..."/></td>
          <td><v-btn v-on:click="onButtonNativeShareText">공유하기</v-btn></td>
        </tr></table>
      </div>
    </div>
    <div class="corner">
      <h2>QR 카메라</h2>
      <div class="content">
        QR 카메라 열기 : <v-btn v-on:click="onButtonNativeGetQRCamera">열기</v-btn>
      </div>
    </div>
    <div class="corner">
      <h2>지문 인식</h2>
      <div class="content">
        지문인식 가능 여부 코드: <code>{{ available_fp_code }}</code><p/>
        지문인식 가능 여부 확인 : <v-btn v-on:click="onButtonNativeIsAvailableFingerPrint">확인하기</v-btn><p/>
        지문인식 등록 : <v-btn v-on:click="onButtonNativeRegisterFingerPrint">등록하기(Only And)</v-btn><p/>
        지문인식 시도 : <v-btn v-on:click="onButtonNativeGetFingerPrint">시도하기</v-btn><p/>
        지문인식 시도 성공? : <code>{{ result_fp_succeed }}</code><p/>
        지문인식 시도 메시지 : <code>{{ result_fp_reason }}</code>
      </div>
    </div>
    <div class="corner">
      <h2>테마 설정</h2>
      <div class="content">
        다크모드 여부 : <code>{{ is_dark_mode }}</code><p/>
        다크모드 여부 확인 : <v-btn v-on:click="onButtonNativeGetDarkMode">불러오기</v-btn><p/>
        다크모드 만들기 : <v-btn v-on:click="onButtonNativeSetDarkMode">다크모드 실행</v-btn><p/>
        라이트모드 만들기 : <v-btn v-on:click="onButtonNativeSetLightMode">라이트모드 실행</v-btn>
      </div>
    </div>
    <div class="corner">
      <h2>푸시 토큰</h2>
      <div class="content">
        푸시 토큰 : <v-text-field row-height="15" rows="1" outlined v-model="push_token" placeholder="푸시 토큰 영역"/><p/>
        푸시 토큰 수신 여부 : <code>{{ push_token_received }}</code><p/>
        <v-btn v-on:click="onButtonNativeGetPushToken">푸시 토큰 가져오기</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>JWT</h2>
      <div class="content">
        <table><tr>
          <td>토큰 저장하기(Access) :</td>
          <td><v-text-field row-height="10" rows="1" outlined v-model="access_token" placeholder="Enter access_token..."/></td>
          <td><v-btn v-on:click="onButtonNativeSaveAccessToken">저장하기</v-btn></td>
        </tr></table>
        <table><tr>
          <td>토큰 저장하기 (Refresh) :</td>
          <td><v-text-field row-height="15" rows="1" outlined v-model="refresh_token" placeholder="Enter refresh_token..."/></td>
          <td><v-btn v-on:click="onButtonNativeSaveRefreshToken">저장하기</v-btn></td>
        </tr></table>
        두 토큰 모두 불러오기 : <v-btn v-on:click="onButtonNativeLoadAccessRefreshToken">불러오기</v-btn><p/>
        불러온 Access Token: <code>{{ access_token }}</code><p/>
        불러온 Refresh Token : <code>{{ refresh_token }}</code>
      </div>
    </div>
    <div class="corner">
      <h2>오퍼월 SDK 연동</h2>
      <div class="content">
        초기화(Development) : <v-btn v-on:click="onButtonNativeOfferwallInitDevelopment">초기화 시작</v-btn><p/>
        초기화(Production) : <v-btn v-on:click="onButtonNativeOfferwallInitProduction">초기화 시작</v-btn><p/>
        현재 초기화 상태 불러오기 : <v-btn v-on:click="onButtonNativeOfferwallGetInitStatus">초기화 상태 불러오기</v-btn><p/>
        초기 상태 : <code>{{ offerwall_init_status }}</code><p/>
        초기 상태 메시지 : <code>{{ offerwall_init_message }}</code><p/>
        <table><tr>
          <td>UID 설정 :</td>
          <td><v-text-field row-height="15" rows="1" outlined v-model="offerwall_uid" placeholder="Enter offerwall_uid..."/></td>
          <td><v-btn v-on:click="onButtonNativeOfferwallSetUid">설정 완료</v-btn></td>
          <td><v-btn v-on:click="onButtonNativeOfferwallSetUidNull">UID 설정 초기화(null)</v-btn></td>
        </tr></table>
        오퍼월 UID, 생일(1983), 성별(MALE) 설정 : <v-btn v-on:click="onButtonNativeOfferwallSetUidBirthGender">지정된 값으로 설정</v-btn><p/>
        오퍼월 Show : <v-btn v-on:click="onButtonNativeOfferwallShow">열기</v-btn><p/>
        오퍼월 Tag Show : <v-btn v-on:click="onButtonNativeOfferwallShowTag">열기</v-btn><p/>
        오퍼월 Ads Show : <v-btn v-on:click="onButtonNativeOfferwallShowAds">열기</v-btn><p/>
        오퍼월 Help Show : <v-btn v-on:click="onButtonNativeOfferwallShowHelp">열기</v-btn><p/>
        오퍼월 Reward 가능 여부 : <v-btn v-on:click="onButtonNativeOfferwallAvailableReward">불러오기</v-btn><p/>
        적립가능리워드이름(name) : <code>{{ offerwall_reward_name }}</code><p/>
        단위(unit) : <code>{{ offerwall_reward_unit }}</code><p/>
        포인트(points) : <code>{{ offerwall_reward_points }}</code><p/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      is_rooting: false,
      trackingAuthorizationStatus: "",
      isInstalledApp: false,
      url_scheme_link: 'pay?PAY_NO=PAY202303150000916',
      member_number: 'member_number',
      access_token: 'access_token',
      refresh_token: 'refresh_token',
      share_text: 'share_text',
      push_token_received: false,
      received_on_resume_count: 0,
      received_on_pause_count: 0,
      app_version_code: -1,
      app_version_name: "",
      store_version_name: "",
      update_available: false,
      update_available_flexible: false,
      update_available_immediate: false,
      update_available_priority: -1,
      update_available_days: -1,
      update_downloaded: false,
      update_downloaded_message: "",
      update_canceled: false,
      update_canceled_message: ""
    }
  },
  props: {
    available_fp_code: Boolean,
    received_on_resume_time: String,
    received_on_pause_time: String,
    notification_permission_enabled: Boolean,
    notification_permission_request_type: Number,
    is_dark_mode: String,
    push_token: String,
    result_fp_succeed: Boolean,
    result_fp_reason: String,
    result_send_fcm_code: String,
    result_send_fcm_message: String,
    offerwall_init_status: String,
    offerwall_init_message: String,
    offerwall_uid: String,
    offerwall_reward_name: String,
    offerwall_reward_unit: String,
    offerwall_reward_points: Number,
  },
  mounted() {
    window.nativeInterface = {
      nativeResultGetTrackingAuthorizationState: (event) => {
        // notDetermined : 사용자가 선택한 적 없음
        // authorized : 사용자가 허용함
        // denied : 사용자가 거절함
        // restricted : 제한됨
        // notNeeded : iOS 14 미만. 앱 추척 허용 필요 없음
        this.trackingAuthorizationStatus = event.status
      },
      nativeResultIsRooting: (event) => {
        this.is_rooting = event.is_rooting
      },
      nativeResultGetAppVersion: (event) => {
        this.app_version_code = event.code
        this.app_version_name = event.name
      },
      nativeResultGetAppVersionLastest: (event) => {
        this.store_version_name = event.name
      },
      nativeResultUpdateAvailable: (event) => {
        this.update_available = true // 해당 함수 진입시 해당 단말의 스토어에서 업데이트 가능함
        this.update_available_flexible = event.flexible // 권장 업데이트 가능 여부
        this.update_available_immediate = event.immediate // 즉시 업데이트 가능 여부
        this.update_available_priority = event.priority // 우선순위
        this.update_available_days = event.days // 스토어에 업데이트 제공 이후의 일 수
        this.update_downloaded = false
        this.update_canceled = false
      },
      nativeResultUpdateCanceled: (event) => {
        this.update_canceled = true
        this.update_canceled_message = event.message
      },
      nativeResultUpdateDownloaded: (event) => {
        this.update_downloaded = true
        this.update_downloaded_message = event.message
      },
      nativeResultOnResume: (event) => {
        if (event.onResume === true) {
          this.received_on_resume_count = this.received_on_resume_count + 1
          this.received_on_resume_time = Date.now() + ""
        }
      },
      nativeResultOnPause: (event) => {
        if (event.onPause === true) {
          this.received_on_pause_count = this.received_on_pause_count + 1
          this.received_on_pause_time = Date.now() + ""
        }
      },
      nativeResultCheckPermissionNotification: (event) => {
        this.notification_permission_enabled = event.enable
      },
      nativeResultRequestPermissionNotification: (event) => {
        // notification_permission_request_type : 0 or 2 인 경우, 앱 설정으로 이동
        this.notification_permission_enabled = event.permission
      },
      nativeResultRequestPermissionNotificationType: (event) => {
        // 0 : Android 12 이하
        // 1 : Android 13 이샹 최초 시도
        // 2 : 샤용자가 알림 허용을 거부
        this.notification_permission_request_type = event.type
      },
      nativeResultGetFingerPrint: (event) => {
        this.result_fp_succeed = event.succeed
        this.result_fp_reason = event.reason
      },
      nativeResultOfferwallAvailableReward: (event) => {
        this.offerwall_reward_name = event.name
        this.offerwall_reward_unit = event.unit
        this.offerwall_reward_points = event.points
      },
      nativeResultBringImage: (event) => {
        this.bring_image_code = event.code
        this.bring_image_base64 = event.image
      },
      nativeResultSendPushToken: (event) => {
        this.result_send_fcm_code = event.code
        this.result_send_fcm_message = event.message
      },
      nativeResultIsAvailableFingerPrint: (event) => {
        // 1 : succeed - 지문 인식 사용 가능
        // 2 : no fingerprints registered : 등록된 지문 없음
        // 1000 : no hardware - 하드웨어 없음
        // 1001 : hw unavailable - 지문 인식 사용 불가
        // 9999 : something problem : 그 외 오류
        this.available_fp_code = event.code + '' === '1';
      },
      nativeResultGetDarkMode: (event) => {
        this.is_dark_mode = event.mode
      },
      nativeResultGetPushToken: (event) => {
        this.push_token = event.token
      },
      nativeResultReceivedPushToken: (event) => {
        this.push_token_received = event.received
      },
      nativeResultLoadAccessRefreshToken: (event) => {
        this.access_token = event.accessToken
        this.refresh_token = event.refreshToken
      },
      nativeResultOfferwallGetInitStatus: (event) => {
        this.offerwall_init_status = event.status
      },
      nativeResultOfferwallInitDevelopment: (event) => {
        this.offerwall_init_message = event.message
        this.onButtonNativeOfferwallGetInitStatus();
      },
      nativeResultOfferwallInitProduction: (event) => {
        this.offerwall_init_message = event.message
        this.onButtonNativeOfferwallGetInitStatus();
      },
    }
  },

  methods: {
    onButtonNativeGetAppVersion() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeGetAppVersion()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeGetAppVersion.postMessage("");
    },
    onButtonNativeGetAppVersionLastest() {
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeGetAppVersionLastest.postMessage("");
    },
    /**
     * 권장 업데이트 시작.
     * update_available 와 update_available_flexible 가 true 일 때 정상 동작함
     */
    onButtonNativeUpdateFlexible() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeUpdateFlexible()
    },
    /**
     * 즉시 업데이트 시작.
     * update_available 와 update_available_immediate 가 true 일 때 정상 동작함
     */
    onButtonNativeUpdateImmediate() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeUpdateImmediate()
    },
    /**
     * 권장 업데이트시, 다운로드를 수행하고 완료되었을 때 해당 함수를 호출한다
     */
    onButtonNativeUpdateComplete() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeUpdateComplete()
    },
    onButtonNativeOpenStore() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOpenStore()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOpenStore.postMessage("");
    },
    onButtonNativeCheckPermissionNotification() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeCheckPermissionNotification()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeCheckPermissionNotification.postMessage("");
    },

    onButtonNativeRequestPermissionNotification() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeRequestPermissionNotification()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeRequestPermissionNotification.postMessage("");
    },

    onButtonNativeRequestPermissionNotificationType() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeRequestPermissionNotificationType()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeRequestPermissionNotificationType.postMessage("");
    },
    onButtonOpenNaver() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeUrlOpenBrowser("https://m.naver.com")
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeUrlOpenBrowser.postMessage("https://m.naver.com");
    },
    onButtonConfirm() {
      confirm("확인하시겠습니까?")
    },
    onButtonGetTrackingAuthorizationStatus() {
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeGetTrackingAuthorizationStatus.postMessage("");
    },
    onButtonRequestTrackingAuthorization() {
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeRequestTrackingAuthorization.postMessage("");
    },
    onButtonUrlSchemeHref() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        location.href = "intent://" + this.url_scheme_link + "#Intent;scheme=sobeit;package=com.danal.sobeit.aos;end";
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1) {
        let visitTime = (new Date()).getTime();
        let href_url = "sobeit://" + this.url_scheme_link;
        setTimeout(function () {
          if ((new Date()).getTime() - visitTime < 100) {
            location.href = "https://itunes.apple.com/app/id1669976346";
          }
        }, 50);
        setTimeout(function () {
          location.href = href_url
        }, 0);
      }
    },
    onButtonNativeFinish() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeFinish()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeFinish.postMessage("");
    },

    onButtonNativeGetQRCamera() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeGetQRCamera()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeGetQRCamera.postMessage("");
    },

    onButtonNativeIsAvailableFingerPrint() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeIsAvailableFingerPrint()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeIsAvailableFingerPrint.postMessage("");
    },

    onButtonNativeRegisterFingerPrint() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeRegisterFingerPrint()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeRegisterFingerPrint.postMessage("");
    },

    onButtonNativeGetFingerPrint() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeGetFingerPrint("제목", "내용", "취소문구")
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeGetFingerPrint.postMessage({title: "제목", content: "내용", cancel: "취소문구"});
    },

    onButtonNativeGetDarkMode() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeGetDarkMode();
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeGetDarkMode.postMessage("");
    },

    onButtonNativeSetDarkMode() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeSetDarkMode();
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeSetDarkMode.postMessage("");
    },

    onButtonNativeSetLightMode() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeSetLightMode();
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeSetLightMode.postMessage("");
    },

    onButtonNativeGetPushToken() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeGetPushToken();
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeGetPushToken.postMessage("");
    },
    onButtonNativeSendPushToken() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeSendPushToken(this.member_number);
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeSendPushToken.postMessage({member: this.member_number});
    },
    onButtonNativeShareText() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeShareText(this.share_text);
    },
    onButtonNativeSaveAccessToken() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeSaveAccessToken(this.access_token);
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeSaveAccessToken.postMessage({token: this.access_token});
    },
    onButtonNativeSaveRefreshToken() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeSaveRefreshToken(this.refresh_token);
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeSaveRefreshToken.postMessage({token: this.refresh_token});
    },
    onButtonNativeLoadAccessRefreshToken() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeLoadAccessRefreshToken();
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeLoadAccessRefreshToken.postMessage("");
    },
    onButtonNativeOfferwallInitDevelopment() {
      let splashHidden = true
      let listTitle = "바로적립받기테스트"
      let buttonType = "Icon"  // Icon or Text
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallInitDevelopment(splashHidden, listTitle, buttonType)
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallInitDevelopment.postMessage({
          splashHidden: splashHidden,
          listTitle: listTitle,
          buttonType: buttonType
        });
    },
    onButtonNativeOfferwallInitProduction() {
      let splashHidden = true
      let listTitle = "바로적립받기테스트"
      let buttonType = "Icon"  // Icon or Text
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallInitProduction(splashHidden, listTitle, buttonType)
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallInitProduction.postMessage({
          splashHidden: splashHidden,
          listTitle: listTitle,
          buttonType: buttonType
        });
    },
    onButtonNativeOfferwallGetInitStatus() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallGetInitStatus()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallGetInitStatus.postMessage("");
    },
    onButtonNativeOfferwallShow() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallShow()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallShow.postMessage("");
    },
    onButtonNativeOfferwallShowTag() {
      let tab_slug = "shopping"
      let tag_slug = "fashion"
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallShowTag(tab_slug, tag_slug)
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallShowTag.postMessage({tabSlug: tab_slug, tagSlug: tag_slug});
    },
    onButtonNativeOfferwallShowAds() {
      let ad_id = 4873
      let keep_parent = true
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallShowAds(ad_id, keep_parent)
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallShowAds.postMessage({adId: ad_id, keepParent: keep_parent});
    },
    onButtonNativeOfferwallSetUid() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallSetUid(this.offerwall_uid)
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallSetUid.postMessage({uid: this.offerwall_uid});
    },
    onButtonNativeOfferwallSetUidNull() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallSetUidNull()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallSetUidNull.postMessage("");
    },
    onButtonNativeOfferwallSetUidBirthGender() {
      let birth = 1983
      let gender = "MALE" // UNKNOWN, MALE, FEMALE
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallSetUidBirthGender(this.offerwall_uid, birth, gender)
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallSetUidBirthGender.postMessage({
          uid: this.offerwall_uid,
          birth: birth,
          gender: gender
        });
    },
    onButtonNativeOfferwallShowHelp() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallShowHelp()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallShowHelp.postMessage("");
    },
    onButtonNativeOfferwallAvailableReward() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.sobeit.nativeOfferwallAvailableReward()
      if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1)
        window.webkit.messageHandlers.nativeOfferwallAvailableReward.postMessage("");
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.corner {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  padding: 10px;
  text-align: left;
}

.content {
  padding: 0 0 0 10px;
  margin: 0 0;
  text-align: left;
}

div {
  margin: 10px 10px;
}

h1 {
  text-align: center;
}

h2 {
  text-align: left;
  font-size: 13px;
  color: #aaaaaa;
  padding-bottom: 15px;
}

h3 {
  text-align: center;
  margin: 3px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>
