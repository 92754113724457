<template>
  <div class="hello">
    <h1>Dalcon</h1>
    <div class="corner">
      <h2>Dialog 확인</h2>
      <div class="content">
        InputType "date" : <input type="date"/><br/>
      </div>
    </div>
    <div class="corner">
      <h2>이미지 다운로드</h2>
      <div class="content">
        <v-btn v-on:click="onButtonDownloadImage">다운로드</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>window.open 대체 방법</h2>
      <div class="content">
        주소 : <v-text-field row-height="15" rows="1" outlined v-model="open_address" placeholder="주소 영역"/><p/>
        주소 열기 : <v-btn v-on:click="onButtonOpenAddress">주소 열기</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>앱 현재 버전</h2>
      <div class="content">
        앱 버전 가져오기(AOS/IOS) : <v-btn v-on:click="onButtonNativeGetAppVersion">가져오기</v-btn><p/>
        앱 버전(AOS/IOS) : <code>{{ app_version_code }} ({{ app_version_name }})</code><p/>
        스토어 버전 가져오기(IOS) : <v-btn v-on:click="onButtonNativeGetAppVersionLast">가져오기</v-btn><p/>
        스토어 버전(IOS) : <code>{{ store_version_name }}</code><p/>
      </div>
      <h2>앱 업데이트</h2>
      <div class="content">
        업데이트 가능 여부(AOS/IOS) : <code>{{ update_available }}</code><p/>
        권장 업데이트 가능 여부(AOS) : <code>{{ update_available_flexible }}</code><p/>
        즉시 업데이트 가능 여부(AOS) : <code>{{ update_available_immediate }}</code><p/>
        업데이트 우선순위(AOS) : <code>{{ update_available_priority }}</code><p/>
        스토어 업데이트 제공 이후의 일 수(AOS) : <code>{{ update_available_days }}</code><p/>
        권장 업데이트 시작(AOS) : <v-btn v-on:click="onButtonNativeUpdateFlexible">업데이트 시작</v-btn><p/>
        즉시 업데이트 시작(AOS) : <v-btn v-on:click="onButtonNativeUpdateImmediate">업데이트 시작</v-btn><p/>
        업데이트 다운로드 됨(AOS) : <code>{{ update_downloaded }}</code><p/>
        업데이트 다운로드 됨 메시지(AOS) : <code>{{ update_downloaded_message }}</code><p/>
        업데이트 취소됨(AOS) : <code>{{ update_canceled }}</code><p/>
        업데이트 취소됨 메시지(AOS) : <code>{{ update_canceled_message }}</code><p/>
        업데이트 완료 시작(AOS) : <v-btn v-on:click="onButtonNativeUpdateComplete">시작</v-btn><p/>
        스토어 열기(AOS/IOS) : <v-btn v-on:click="onButtonNativeOpenStore">열기</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>앱 생명 주기</h2>
      <div class="content">
        onResume 횟수 : <code>{{ received_on_resume_count }}</code><p/>
        onResume 시간 : <code>{{ received_on_resume_time }}</code><p/>
        onPause 횟수 : <code>{{ received_on_pause_count }}</code><p/>
        onPause 시간 : <code>{{ received_on_pause_time }}</code><p/>
        앱 종료 : <v-btn v-on:click="onButtonNativeFinish">Finish 호출하기</v-btn>
      </div>
    </div>
    <div class="corner">
      <h2>앱 알림 권한</h2>
      <div class="content">
        알림 권한 허용 확인 : <v-btn v-on:click="onButtonNativeCheckPermissionNotification">확인하기</v-btn><p/>
        알림 권한 허용 : <code>{{ notification_permission_enabled }}</code><p/>
        알림 권한 요청 : <v-btn v-on:click="onButtonNativeRequestPermissionNotification">요청하기</v-btn><p/>
        권한 타입 확인 : <v-btn v-on:click="onButtonNativeRequestPermissionNotificationType">확인하기</v-btn><p/>
        권한 타입 : <code>{{ notification_permission_request_type }}</code>
      </div>
    </div>
    <div class="corner">
      <h2>푸시 토큰</h2>
      <div class="content">
        푸시 토큰 : <v-text-field row-height="15" rows="1" outlined v-model="push_token" placeholder="푸시 토큰 영역"/><p/>
        푸시 토큰 수신 여부 : <code>{{ push_token_received }}</code><p/>
        <v-btn v-on:click="onButtonNativeGetPushToken">푸시 토큰 가져오기</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>데이터 저장</h2>
      <div class="content">
        <table><tr>
          <td>키</td>
          <td><v-text-field row-height="10" rows="1" outlined v-model="data_key" placeholder="Enter Key..."/></td>
        </tr><tr>
          <td>값</td>
          <td><v-text-field row-height="15" rows="1" outlined v-model="data_value" placeholder="Enter Value..."/></td>
        </tr></table>
        값 저장하기 : <v-btn v-on:click="onButtonSaveData">저장하기</v-btn><p/>
        값 불러오기 : <v-btn v-on:click="onButtonLoadData">불러오기</v-btn><p/>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";

export default {
  name: 'HelloWorld',
  data() {
    return {
      social_provider: "확인 필요",
      social_email: "확인 필요",
      social_uid: "확인 필요",
      social_error: "에러시 메시지",
      trackingAuthorizationStatus: "",
      isInstalledApp: false,
      url_scheme_link: 'pay?PAY_NO=PAY202303150000916',
      member_number: 'member_number',
      data_key: 'key',
      data_value: 'data_value',
      share_text: 'share_text',
      push_token_received: false,
      received_on_resume_count: 0,
      received_on_pause_count: 0,
      app_version_code: -1,
      app_version_name: "",
      store_version_name: "",
      update_available: false,
      update_available_flexible: false,
      update_available_immediate: false,
      update_available_priority: -1,
      update_available_days: -1,
      update_downloaded: false,
      update_downloaded_message: "",
      update_canceled: false,
      update_canceled_message: "",
      location_data_lat: 0.0,
      location_data_lng: 0.0,
      location_data_error: "NONE",
    }
  },
  props: {
    open_address: String,
    available_fp_code: Boolean,
    received_on_resume_time: String,
    received_on_pause_time: String,
    config_gps_enabled: Boolean,
    location_permission_enabled: Boolean,
    location_permission_request_type: Number,
    location_permission_request_type_context: String,
    notification_permission_enabled: Boolean,
    notification_permission_request_type: Number,
    is_dark_mode: String,
    push_token: String,
    result_fp_succeed: Boolean,
    result_fp_reason: String,
    result_send_fcm_code: String,
    result_send_fcm_message: String,
  },
  mounted() {
    window.nativeInterface = {
      nativeResultSocialUpdate: (event) => {
        // NONE : 로그인 되어있지 않음
        // GOOGLE : 구글로 로그인 됨
        // APPLE : 애플로 로그인 됨
        this.social_provider = event.provider
        this.social_email = event.email
        this.social_uid = event.uid
        this.social_error = ""
      },
      nativeResultGetTrackingAuthorizationState: (event) => {
        // notDetermined : 사용자가 선택한 적 없음
        // authorized : 사용자가 허용함
        // denied : 사용자가 거절함
        // restricted : 제한됨
        // notNeeded : iOS 14 미만. 앱 추척 허용 필요 없음
        this.trackingAuthorizationStatus = event.status
      },
      nativeResultGetAppVersion: (event) => {
        this.app_version_code = event.code
        this.app_version_name = event.name
      },
      nativeResultGetAppVersionLast: (event) => {
        this.store_version_name = event.name
      },
      nativeResultUpdateAvailable: (event) => {
        this.update_available = true // 해당 함수 진입시 해당 단말의 스토어에서 업데이트 가능함
        this.update_available_flexible = event.flexible // 권장 업데이트 가능 여부
        this.update_available_immediate = event.immediate // 즉시 업데이트 가능 여부
        this.update_available_priority = event.priority // 우선순위
        this.update_available_days = event.days // 스토어에 업데이트 제공 이후의 일 수
        this.update_downloaded = false
        this.update_canceled = false
      },
      nativeResultUpdateCanceled: (event) => {
        this.update_canceled = true
        this.update_canceled_message = event.message
      },
      nativeResultUpdateDownloaded: (event) => {
        this.update_downloaded = true
        this.update_downloaded_message = event.message
      },
      nativeResultOnResume: (event) => {
        if (event.onResume === true) {
          this.received_on_resume_count = this.received_on_resume_count + 1
          this.received_on_resume_time = Date.now() + ""
        }
      },
      nativeResultOnPause: (event) => {
        if (event.onPause === true) {
          this.received_on_pause_count = this.received_on_pause_count + 1
          this.received_on_pause_time = Date.now() + ""
        }
      },
      nativeResultIsNetworkEnable: (event) => {
        this.config_network_enabled = event.enable
      },
      nativeResultIsGpsEnable: (event) => {
        this.config_gps_enabled = event.enable
      },
      nativeResultCheckPermissionLocation: (event) => {
        this.location_permission_enabled = event.enable
      },
      nativeResultRequestPermissionLocation: (event) => {
        // notification_permission_request_type : 2 인 경우, 앱 설정으로 이동
        this.location_permission_enabled = event.permission
      },
      nativeResultRequestPermissionLocationType: (event) => {
        // 0 : 최초 시도
        // 2 : 샤용자가 알림 허용을 거부
        this.location_permission_request_type = event.type
        if (this.location_permission_request_type === 0) {
          this.location_permission_request_type_context =  "위치 권한 요청시 위치 권한 팝업 뜸"
        }
        if (this.location_permission_request_type === 2) {
          this.location_permission_request_type_context =  "권한 요청을 사용자가 한 번이라도 거부함. 위치 권한 재요청시 앱 설정으로 이동"
        }
      },
      nativeResultLocationData: (event) => {
        this.location_data_lat = event.lat
        this.location_data_lng = event.lng
      },
      nativeResultCheckPermissionNotification: (event) => {
        this.notification_permission_enabled = event.enable
      },
      nativeResultRequestPermissionNotification: (event) => {
        // notification_permission_request_type : 0 or 2 인 경우, 앱 설정으로 이동
        this.notification_permission_enabled = event.permission
      },
      nativeResultRequestPermissionNotificationType: (event) => {
        // 0 : Android 12 이하
        // 1 : Android 13 이샹 최초 시도
        // 2 : 샤용자가 알림 허용을 거부
        this.notification_permission_request_type = event.type
      },
      nativeResultSendPushToken: (event) => {
        this.result_send_fcm_code = event.code
        this.result_send_fcm_message = event.message
      },
      nativeResultGetPushToken: (event) => {
        this.push_token = event.token
      },
      nativeResultReceivedPushToken: (event) => {
        this.push_token_received = event.received
      },
      nativeResultLoadData: (event) => {
        this.data_key = event.key
        this.data_value = event.value
      },
    }
  },

  methods: {
    onButtonDownloadImage() {
      html2canvas(document.body, {})
          .then((canvas) => {
            // Convert canvas to data URL
            const dataUrl = canvas.toDataURL("image/jpeg");

            // Create a temporary link element
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = `icon.png`;
            link.click();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    },
    onButtonNativeGetAppVersion() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeGetAppVersion()
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeGetAppVersion.postMessage("");
    },
    onButtonNativeGetAppVersionLast() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeGetAppVersionLast.postMessage("");
    },
    /**
     * 권장 업데이트 시작.
     * update_available 와 update_available_flexible 가 true 일 때 정상 동작함
     */
    onButtonNativeUpdateFlexible() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeUpdateFlexible()
    },
    /**
     * 즉시 업데이트 시작.
     * update_available 와 update_available_immediate 가 true 일 때 정상 동작함
     */
    onButtonNativeUpdateImmediate() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeUpdateImmediate()
    },
    /**
     * 권장 업데이트시, 다운로드를 수행하고 완료되었을 때 해당 함수를 호출한다
     */
    onButtonNativeUpdateComplete() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeUpdateComplete()
    },
    onButtonNativeOpenStore() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeOpenStore()
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeOpenStore.postMessage("");
    },
    onButtonNativeIsNetworkEnable() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeIsNetworkEnable()
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeIsNetworkEnable.postMessage("");
    },
    onButtonNativeCheckPermissionNotification() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeCheckPermissionNotification()
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeCheckPermissionNotification.postMessage("");
    },
    onButtonNativeRequestPermissionNotification() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeRequestPermissionNotification()
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeRequestPermissionNotification.postMessage("");
    },
    onButtonNativeRequestPermissionNotificationType() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeRequestPermissionNotificationType()
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeRequestPermissionNotificationType.postMessage("");
    },
    onButtonOpenAddress() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeUrlOpenBrowser(this.open_address)
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeUrlOpenBrowser.postMessage(this.open_address);
    },
    onButtonNativeFinish() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeFinish()
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeFinish.postMessage("");
    },
    onButtonNativeGetPushToken() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeGetPushToken();
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeGetPushToken.postMessage("");
    },
    onButtonNativeSendPushToken() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeSendPushToken(this.member_number);
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeSendPushToken.postMessage({member: this.member_number});
    },
    onButtonSaveData() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeSaveData(this.data_key, this.data_value);
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeSaveData.postMessage({key: this.data_key, value: this.data_value});
    },
    onButtonLoadData() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('android') !== -1)
        window.dalcon.nativeLoadData(this.data_key);
      if (agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1 || agent.indexOf('macintosh') !== -1)
        window.webkit.messageHandlers.nativeLoadData.postMessage({key: this.data_key});
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.corner {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  padding: 10px;
  text-align: left;
}

.content {
  padding: 0 0 0 10px;
  margin: 0 0;
  text-align: left;
}

div {
  margin: 10px 10px;
}

h1 {
  text-align: center;
}

h2 {
  text-align: left;
  font-size: 13px;
  color: #aaaaaa;
  padding-bottom: 15px;
}

h3 {
  text-align: center;
  margin: 3px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>
