import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import GiveHeart from '../components/GiveHeart.vue'
import SobeIt from '../components/SobeIt.vue';
import Pple from '../components/Pple.vue';
import Dalcon from '../components/Dalcon.vue';
import SalesDoctor from "../components/SalesDoctor.vue"
import MasterPick from "@/components/MasterPick.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sales-doctor',
    name: 'SalesDoctor',
    component: SalesDoctor
  },
  {
    path: '/masterpick',
    name: 'MasterPick',
    component: MasterPick
  },
  {
    path: '/sobe-it',
    name: 'SobeIt',
    component: SobeIt
  },
  {
    path: '/giveheart',
    name: 'GiveHeart',
    component: GiveHeart
  },
  {
    path: '/pple',
    name: 'Pple',
    component: Pple
  },
  {
    path: '/dalcon',
    name: 'Dalcon',
    component: Dalcon
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
