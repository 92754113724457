<template>
  <div class="hello">
    <h1>기브하트</h1>
    <div class="corner">
      <h2>앱 현재 버전</h2>
      <div class="content">
        앱 버전 가져오기 : <v-btn v-on:click="onButtonNativeGetAppVersion">가져오기</v-btn><p/>
        앱 버전 : <code>{{ app_version_code }} ({{ app_version_name }})</code><p/>
      </div>
      <h2>앱 업데이트</h2>
      <div class="content">
        업데이트 가능 여부 : <code>{{ update_available }}</code><p/>
        권장 업데이트 가능 여부 : <code>{{ update_available_flexible }}</code><p/>
        즉시 업데이트 가능 여부 : <code>{{ update_available_immediate }}</code><p/>
        업데이트 우선순위 : <code>{{ update_available_priority }}</code><p/>
        스토어 업데이트 제공 이후의 일 수 : <code>{{ update_available_days }}</code><p/>
        권장 업데이트 시작 : <v-btn v-on:click="onButtonNativeUpdateFlexible">업데이트 시작</v-btn><p/>
        즉시 업데이트 시작 : <v-btn v-on:click="onButtonNativeUpdateImmediate">업데이트 시작</v-btn><p/>
        업데이트 다운로드 됨 : <code>{{ update_downloaded }}</code><p/>
        업데이트 다운로드 됨 메시지 : <code>{{ update_downloaded_message }}</code><p/>
        업데이트 취소됨 : <code>{{ update_canceled }}</code><p/>
        업데이트 취소됨 메시지 : <code>{{ update_canceled_message }}</code><p/>
        업데이트 완료 시작 : <v-btn v-on:click="onButtonNativeUpdateComplete">시작</v-btn><p/>
        스토어 열기 : <v-btn v-on:click="onButtonNativeOpenStore">열기</v-btn><p/>
      </div>
    </div>
    <div class="corner">
      <h2>앱 생명 주기</h2>
      <div class="content">
        onResume 횟수 : <code>{{ received_on_resume_count }}</code><p/>
        onResume 시간 : <code>{{ received_on_resume_time }}</code><p/>
        onPause 횟수 : <code>{{ received_on_pause_count }}</code><p/>
        onPause 시간 : <code>{{ received_on_pause_time }}</code><p/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      received_on_resume_count: 0,
      received_on_pause_count: 0,
      received_on_resume_time: "",
      received_on_pause_time: "",
      app_version_code: -1,
      app_version_name: "",
      update_available: false,
      update_available_flexible: false,
      update_available_immediate: false,
      update_available_priority: -1,
      update_available_days: -1,
      update_downloaded: false,
      update_downloaded_message: "",
      update_canceled: false,
      update_canceled_message: ""
    }
  },
  mounted() {
    window.nativeInterface = {
      nativeResultGetAppVersion: (event) => {
        this.app_version_code = event.code
        this.app_version_name = event.name
      },
      nativeResultUpdateAvailable: (event) => {
        this.update_available = true // 해당 함수 진입시 해당 단말의 스토어에서 업데이트 가능함
        this.update_available_flexible = event.flexible // 권장 업데이트 가능 여부
        this.update_available_immediate = event.immediate // 즉시 업데이트 가능 여부
        this.update_available_priority = event.priority // 우선순위
        this.update_available_days = event.days // 스토어에 업데이트 제공 이후의 일 수
        this.update_downloaded = false
        this.update_canceled = false
      },
      nativeResultUpdateCanceled: (event) => {
        this.update_canceled = true
        this.update_canceled_message = event.message
      },
      nativeResultUpdateDownloaded: (event) => {
        this.update_downloaded = true
        this.update_downloaded_message = event.message
      },
      nativeResultOnResume: (event) => {
        if (event.onResume === true) {
          this.received_on_resume_count = this.received_on_resume_count + 1
          this.received_on_resume_time = Date.now() + ""
        }
      },
      nativeResultOnPause: (event) => {
        if (event.onPause === true) {
          this.received_on_pause_count = this.received_on_pause_count + 1
          this.received_on_pause_time = Date.now() + ""
        }
      },
    }
  },

  methods: {
    onButtonNativeGetAppVersion() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.giveHeart.nativeGetAppVersion()
    },
    /**
     * 권장 업데이트 시작.
     * update_available 와 update_available_flexible 가 true 일 때 정상 동작함
     */
    onButtonNativeUpdateFlexible() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.giveHeart.nativeUpdateFlexible()
    },
    /**
     * 즉시 업데이트 시작.
     * update_available 와 update_available_immediate 가 true 일 때 정상 동작함
     */
    onButtonNativeUpdateImmediate() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.giveHeart.nativeUpdateImmediate()
    },
    /**
     * 권장 업데이트시, 다운로드를 수행하고 완료되었을 때 해당 함수를 호출한다
     */
    onButtonNativeUpdateComplete() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.giveHeart.nativeUpdateComplete()
    },

    onButtonNativeOpenStore() {
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
        window.giveHeart.nativeOpenStore()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.corner {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  padding: 10px;
  text-align: left;
}

.content {
  padding: 0 0 0 10px;
  margin: 0 0;
  text-align: left;
}

div {
  margin: 10px 10px;
}

h1 {
  text-align: center;
}

h2 {
  text-align: left;
  font-size: 13px;
  color: #aaaaaa;
  padding-bottom: 15px;
}

h3 {
  text-align: center;
  margin: 3px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>
